import { computed } from 'vue';

interface macrosImpl {
  carb: number;
  fats: number;
  protein: number;
  kcal: number;
}

export function reduceDecimalsMacros(macros: macrosImpl) {
  const macrosEdited = computed(() => {
    if (!macros) {
      return {
        carb: 0,
        fats: 0,
        protein: 0,
        kcal: 0
      };
    }
    return {
      carb: macros.carb.toFixed(2),
      fats: macros.fats.toFixed(2),
      protein: macros.protein.toFixed(2),
      kcal: macros.kcal.toFixed(2)
    };
  });

  return { macrosEdited };
}
