<template lang="pug">
boxWrapper
  .macros
    titleComponent(title="Macronutrientes adaptados")
    macrosLine(
      :current="`${macros.fats}g`",
      name="Grasas",
      color="red",
      :percent="macrosPercent.fats"
    )
    macrosLine(
      :current="`${macros.protein}g`",
      name="Proteinas",
      color="green",
      :percent="macrosPercent.protein"
    )
    macrosLine(
      :current="`${macros.carb}g`",
      name="Carbohidratos",
      color="yellow",
      :percent="macrosPercent.carb"
    )
    macrosLine(
      :current="`${macros.kcal}kcal`",
      name="kcal",
      color="blue",
      :percent="macrosPercent.kcal"
    )
    .alert.green.mt-4 Esta receta esta adaptada para cumplir con tus objetivos
</template>
<script>
import macrosLine from "./macros-line.vue";
import titleComponent from "../title-component.vue";
import boxWrapper from "../box-wrapper.vue";

import { reduceDecimalsMacros } from "@/app/composables/reduceDecimalsMacros";
import { calculatePercent } from "@/app/composables/calculatePercent";

import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "macros-component",
  components: {
    macrosLine,
    titleComponent,
    boxWrapper,
  },
  setup() {
    const store = useStore();
    const macros = computed(() => {
      const { macrosEdited } = reduceDecimalsMacros(
        store.getters["menuReceiptStore/macros"]
      );
      return macrosEdited.value;
    });

    const macrosSelectedDay = computed(
      () => store.getters["menuStore/getMacrosSelectedDay"]
    );

    const macrosPercent = computed(() => {
      return {
        fats: calculatePercent(macrosSelectedDay.value.fats, macros.value.fats),
        carb: calculatePercent(macrosSelectedDay.value.carb, macros.value.carb),
        protein: calculatePercent(
          macrosSelectedDay.value.protein,
          macros.value.protein
        ),
        kcal: calculatePercent(macrosSelectedDay.value.kcal, macros.value.kcal),
      };
    });
    return {
      macros,
      macrosPercent,
    };
  },
};
</script>
<style lang="scss" scoped>
.alert {
  @apply rounded-lg border-2 px-4 py-2 text-center text-sm;
  &.green {
    @apply border-green-600 bg-green-100 text-green-700;
  }
}
</style>