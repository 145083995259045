<template lang="pug">
.macros-group 
  .macro-wrapper(:class="color")
    .macro-info
      .name {{ name }}
      .grams {{ current }}
    .chart-line
      .color-line(:style="`width: ${percent}%`")
</template>
<script>
export default {
  name: "macros-line",
  props: {
    name: {
      type: String,
    },
    color: {
      type: String,
      default: "bg-green-400",
    },
    percent: {
      type: String,
      default: "0",
    },
    current: {
      type: String,
      default: "",
    },
  },
  setup(props) {},
};
</script>
<style lang="scss" scoped>
.macros-group {
  @apply py-2;
}

.macro-wrapper {
  @apply flex flex-col;

  &.yellow {
    .macro-info .name::before {
      @apply bg-yellow-500;
    }
    .color-line {
      @apply bg-yellow-500;
    }
  }
  &.red {
    .macro-info .name::before {
      @apply bg-red-500;
    }
    .color-line {
      @apply bg-red-500;
    }
  }
  &.blue {
    .macro-info .name::before {
      @apply bg-blue-500;
    }
    .color-line {
      @apply bg-blue-500;
    }
  }
  &.green {
    .macro-info .name::before {
      @apply bg-green-500;
    }
    .color-line {
      @apply bg-green-500;
    }
  }

  .macro-info {
    @apply flex justify-between items-center text-sm text-gray-700 pb-1;
    .name {
      @apply flex items-center;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        position: relative;
        display: block;
        @apply mr-2 rounded-full;
      }
    }
  }

  .chart-line {
    height: 8px;
    @apply w-full bg-gray-200 rounded-full relative overflow-hidden;
    .color-line {
      position: absolute;
      width: 30%;
      height: 8px;
      @apply rounded-full;
    }
  }
}
</style>